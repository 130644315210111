import App from './components/App';

let app = new App();

if (module.hot) {
  module.hot.dispose(function() { });

  module.hot.accept(function() {
    app = new App();
  });
}
