class App {
  constructor() {
    this.onResize = this.onResize.bind(this);

    window.addEventListener('resize', this.onResize);
    setTimeout(() => { this.onResize(); }, 10);
  }
  
  onResize() {
    this.resizeFontSize();
  }

  getWindowSize() {
    this.windowSize = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    return this.windowSize;
  }


  resizeFontSize() {
    let ratio = 0.01641025641; // Rapport : 16px pour 975px de largeur
    let width = this.getWindowSize().width;
    let contentWidth = (width < 1440 ) ? width : 1440;

    if (width < 769) { // Rapport : 16px pour 320px de largeur
      ratio = 0.05;
    }

    var fontSize = contentWidth * ratio;
    document.body.style.fontSize = fontSize + 'px';
  }
}

export default App;